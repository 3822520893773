@import "minima";

@mixin on-laptop() {
  @media screen and (min-width: $on-palm) {
    @content;
  }
}

@mixin on-palm() {
  @media screen and (max-width: $on-palm) {
    @content;
  }
}

$header-size: $spacing-unit * 2;

// Minima overrides

.site-header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  opacity: 0.95;
  height: $header-size;
}

.page-content {
  padding-top: $header-size + $spacing-unit + 6;
  padding-bottom: $spacing-unit / 2;
}

.site-footer {
  //margin-top: $spacing-unit / 2;
  padding-bottom: $spacing-unit / 4;
}


// Site styles

.copyright {
  @include relative-font-size(0.75);
  color: $grey-color;
  text-align: center;
}

.icon {
  height: 1.2em;
  margin-right: 1ex;
}

.hero-image {
  width: 100%;
  background: #000;
  margin-top: -$spacing-unit;
  margin-bottom: $spacing-unit;
}

.col-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@include media-query($on-palm) {
  .col-box {
    display: block;
  }
}


@include on-laptop() {
  .book-rec {
    width: -webkit-calc(50% - #{$spacing-unit / 4});
    width:        -calc(50% - #{$spacing-unit / 4});
  }
}

.book-rec {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: $spacing-unit;
  
  .book-cover {
    img {
      width: 95px;
      max-width: 95px;
      margin-right: $spacing-unit / 2;
    }
  }

  .book-title {
    font-weight: bold;
  }

  .book-author {
    font-style: italic;
    margin-bottom: $spacing-unit / 4;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

@include on-laptop() {
  img.inset-right {
    float: right;
    width: $content-width * .375;
    margin-left: $spacing-unit;
    margin-right: 0;
  }
}

@include on-palm() {
  img.inset-right {
    margin-bottom: $spacing-unit / 2;
  }
}
